import React, { Component } from 'react';
import { Input, Container, Row, Col, Card, CardBody, CardTitle, Button } from 'reactstrap';
import { getApiData, postApiData } from '../fetchApi';
import { Layout } from './Layout';
import moment from 'moment';

class MainLadder extends Component {
    constructor(props) {
        super(props);

        this.state = {
            teams: [], // To store the list of teams from the API
            ladder: [],
            showTeamStats: false, // To control whether to display general stats or team stats
            showPerformanceTeamStats: false,
            teamStats: {}, // To store the stats of the selected team
            performanceComparisonTeamStats: {},
            formats: [], // To store the list of team formats from the API
            selectedFormat: 1, // Default selected format ID
            roster: [],
            comparisonRoster: []
        };
    }

    componentDidMount = async () => {
        // Fetch initial list of teams
        const teams = await getApiData('GET_TEAMS_BY_FORMAT_ID', [this.state.selectedFormat]);
        const ladder = await getApiData('GET_LADDER_BY_FORMAT_ID', [this.state.selectedFormat]);
        const formats = await getApiData('GET_FORMATS');
        this.setState({ teams, formats, ladder });
        
    }

    handleFormatChange = async (event) => {
        const selectedFormat = parseInt(event.target.value);
        const teams = await getApiData('GET_TEAMS_BY_FORMAT_ID', [selectedFormat]);
        const ladder = await getApiData('GET_LADDER_BY_FORMAT_ID', [selectedFormat]);
        this.setState({ selectedFormat, teams, ladder, showTeamStats: false })
    }

    handleTeamClick = async (id) => {
        // Fetch stats for the selected team
        const teamStats = await getApiData('GET_TEAM_STATS_BY_ID', [id, this.state.selectedFormat]);
        const roster = await getApiData("GET_TEAM_ROSTER", [id]);
        if (teamStats) {
            this.setState({ showTeamStats: true, teamStats, performanceComparisonTeamStats: {}, showPerformanceTeamStats:false, roster });
        }
    }

    handlePerformanceComparisonChange = async (event) => {
        const selectedTeamId = event.target.value;
        if (selectedTeamId === 'Select a team to compare to')
            this.setState({ showPerformanceTeamStats: false, performanceComparisonTeamStats: {}, comparisonRoster: [] });

        else {
            const performanceComparisonTeamStats = await getApiData('GET_TEAM_STATS_BY_ID', [selectedTeamId, this.state.selectedFormat]);
            const roster = await getApiData("GET_TEAM_ROSTER", [selectedTeamId]);
            this.setState({ showPerformanceTeamStats: true, performanceComparisonTeamStats, comparisonRoster: roster });
        }
       
    }

    render() {
        const { teams, selectedTeam, showTeamStats, teamStats, verifiedTeams, formats, selectedFormat, performanceComparisonTeamStats, showPerformanceTeamStats, ladder } = this.state;

        return (
            <Container>
                <br />
                    <Row>
                    <Col lg={4}>
                        <label><b>Select a format</b></label>
                            <Input type="select" onChange={this.handleFormatChange} value={selectedFormat}>
                                 {formats.map(format => (
                                    <option key={format.id} value={format.id}>{format.formatName}</option>
                                ))}
                            </Input>
                        </Col>
                    </Row>
                    <Row>
                    {/* First Column */}
                       
                    <Col lg={4}>
                        <br />
                        <div><b>Note: Click on a team name to see detailed stats</b></div>
                        <div style={{ maxHeight: '500px', overflowY: 'auto' }}>
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th>Rank</th>
                                        <th>Team Name</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {teams.length > 0 ? (
                                        ladder.map((ladder) => (
                                            <tr key={ladder.id} onClick={() => this.handleTeamClick(ladder.teamId)}>
                                                <td>{ladder.ladderRank}</td>
                                                <td><span className="ladder-team-name">{ladder.teamName}</span></td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="2">No teams exist for this ladder</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </Col>
                    {/* Second Column */}
                        <Col lg={8}>
                        {showTeamStats && (
                            <>
                                <Row>
                                    <h4>Team Stats</h4>
                                    <Input type="select" onChange={this.handlePerformanceComparisonChange}>
                                        <option key="">Select a team to compare to</option>
                                        {teams.filter(team => team.id !== teamStats.id).map(team => (
                                            <option key={team.id} value={team.id}>{team.teamName}</option>
                                        ))}
                                    </Input>
                                    <Col xs={3} sm={2}>
                                        <br />
                                        <h5>Roster</h5>
                                        <ul style={{ listStyle: 'none', padding: 0, lineHeight: 2.5 }}>
                                            {this.state.roster.map((user) => (
                                                <li key={user.id}>
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        {user.isCaptain === 1 ? <span><b>{user.username}</b></span> : user.username}
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    </Col>
                                    <Col xs={3} sm={4}>
                                        
                                        <br />
                                        <div className="ranking-row">
                                            <p style={{ color: '#A65F08', textAlign:'center', fontWeight:'bold' } }>{teamStats.teamName}</p>
                                            <p><b>Rank:</b> {teamStats.ladderRank}</p>
                                            <p><b>Wins:</b> {teamStats.wins}</p>
                                            <p><b>Losses:</b> {teamStats.losses}</p>
                                            <p><b>Win Rate:</b> {teamStats.winRate}%</p>
                                            <p><b>Loss Rate:</b> {teamStats.lossRate}%</p>
                                            <p><b>Last Match Played:</b>  {moment(teamStats.lastMatchPlayed).format('MM/DD/YYYY hh:mm A')} EST</p>
                                        </div>
                                    </Col>
                                    {showPerformanceTeamStats && (
                                       <>
                                            <Col xs={3} sm={4}>
                                            <br />
                                                <div className="ranking-row">
                                                <p style={{ color: '#A65F08', textAlign: 'center', fontWeight: 'bold' }}>{performanceComparisonTeamStats.teamName}</p>
                                                <p><b>Rank:</b> {performanceComparisonTeamStats.ladderRank}</p>
                                                <p><b>Wins:</b> {performanceComparisonTeamStats.wins}</p>
                                                <p><b>Losses:</b> {performanceComparisonTeamStats.losses}</p>
                                                <p><b>Win Rate:</b> {performanceComparisonTeamStats.winRate}%</p>
                                                <p><b>Loss Rate:</b> {performanceComparisonTeamStats.lossRate}%</p>
                                                <p><b>Last Match Played:</b>  {moment(performanceComparisonTeamStats.lastMatchPlayed).format('MM/DD/YYYY hh:mm A')} EST{}</p>
                                                </div>
                                        </Col>
                                            <Col xs={3} sm={2}>
                                                <br />
                                            <h5>Roster</h5>
                                                <ul style={{ listStyle: 'none', padding: 0, lineHeight: 2.5 }}>
                                                    {this.state.comparisonRoster.map((user) => (
                                                        <li key={user.id}>
                                                            <div className="d-flex align-items-center justify-content-between">
                                                                {user.isCaptain === 1 ? <span><b>{user.username}</b></span> : user.username}
                                                            </div>
                                                        </li>
                                                    ))}
                                                </ul>
                                        </Col>
                                        </>
                                    )}
                            </Row>
                            </>
                         )}
                        </Col>
                    </Row>
                </Container>
            
        );
    }
}

export default MainLadder;
