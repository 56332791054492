import React, { Component } from 'react';
import { Container, Row, Col, Button, Input, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { getApiData, postApiData } from '../fetchApi';
import { Layout } from './Layout';
import Select from 'react-select';
import moment from 'moment';

class ManageTeam extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isCaptain: this.props.isCaptain,
            teamId: this.props.teamId,
            teamName: '', // Placeholder for team name
            roster: [], // Placeholder for current roster
            upcomingMatches: [], // Placeholder for upcoming matches
            pendingJoinRequests: [], // Placeholder for pending join requests
            selectedTeamIdSevens: '', // Placeholder for selected team ID in new match form
            selectedTeamIdFours: '',
            matchDate: '', // Placeholder for match date in new match form
            activeTab: 'rosterTab',
            activeMatchTab: 'createMatchTab',
            pendingMatchRequests: [],
            sentMatchRequests: [],
            pendingMatchSentRequests: [],
            errMsg: "",
            teams: [],
            teamIsChallengingSeven: 0,
            teamIsChallengingFour: 0,
            selectedDate: "",
            cannotApproveMatchErr: "",
            winnerIdSevens: 0,
            winnerIdFours: 0,
            activeCreateMatchTab: '7v7',
            winErrFours: "",
            winErrSevens: "",
            isVacationMode: 0
        };
    }

    componentDidMount = async () => {
        // Fetch data for the team, current roster, upcoming matches, and pending join requests
        await this.fetchTeamData();
    }

    fetchTeamData = async () => {
        // Placeholder function to fetch team data from API
        // Replace with actual API calls
        const teamData = await getApiData("GET_TEAM_BY_ID", [this.state.teamId])
        const teamName = teamData.teamName; // Populate team name from API
        const teamIsChallengingSeven = teamData.isChallengingSeven
        const teamIsChallengingFour = teamData.isChallengingFour
        const isVacationMode = teamData.isVacationMode
        const teams = await getApiData("GET_OPPONENTS_LIST", [this.state.teamId, 1])
        const roster = await getApiData("GET_TEAM_ROSTER", [this.state.teamId]); // Populate current roster from API
        const upcomingMatches = await getApiData("GET_UPCOMING_MATCHES_BY_TEAM_ID", [this.state.teamId]); // Populate upcoming matches from API
        const pendingJoinRequests = await getApiData("GET_PENDING_TEAMS_USER_REQUESTS", [this.state.teamId]); // Populate pending join requests from API
        var pendingMatchRequests = await getApiData("GET_PENDING_MATCH_REQUESTS", [this.state.teamId]); // Populate pending join requests from API
        var pendingMatchSentRequests = await getApiData("GET_PENDING_MATCH_SENT_REQUESTS", [this.state.teamId, 1]);
        pendingMatchRequests = await this.handleOldPendingMatches("pending_match", pendingMatchRequests)
        pendingMatchSentRequests = await this.handleOldPendingMatches("pending_match_sent", pendingMatchSentRequests)
        this.setState({ teamName, roster, upcomingMatches, pendingJoinRequests, pendingMatchRequests, pendingMatchSentRequests, teams, teamIsChallengingSeven, teamIsChallengingFour, isVacationMode });
    }

    handleOldPendingMatches = async (type, pendingMatchArray) =>
    {
        const now = new Date();
        var threshold = type === "pending_match_sent" ? 72 * 60 * 60 * 1000 : 48 * 60 * 60 * 1000 ; // 48 hours in milliseconds

        var updatedPendingMatchRequests = [];
        for (let request of pendingMatchArray) {
            var dateCreated = new Date(request.dateCreated);
            if (now - dateCreated > threshold)
                await postApiData(type === "pending_match_sent" ? "HANDLE_OLD_PENDING_MATCH_SENT_REQUEST" : "HANDLE_OLD_PENDING_MATCH_REQUEST", [request.id]);
            else
                updatedPendingMatchRequests.push(request);

        }

        return updatedPendingMatchRequests
    }

    handleApproveJoinRequest = async (userId) => {
        var requestObj = { userId: userId, teamId: this.state.teamId }
        await postApiData("APPROVE_JOIN_REQUEST", [requestObj])
        const pendingJoinRequests = await getApiData("GET_PENDING_TEAMS_USER_REQUESTS", [this.state.teamId]);
        var roster = await getApiData("GET_TEAM_ROSTER", [this.state.teamId]);
        this.setState({ pendingJoinRequests, roster })
    }

    handleRejectJoinRequest = async (userId) => {
        var requestObj = { userId: userId, teamId: this.state.teamId }
        await postApiData("REJECT_JOIN_REQUEST", [requestObj])
        const pendingJoinRequests = await getApiData("GET_PENDING_TEAMS_USER_REQUESTS", [this.state.teamId]);
        this.setState({ pendingJoinRequests })
    }

    handleCreateMatchSubmit = async (e) => {
        e.preventDefault()
        var newMatchObj = { Id: 0, Rank: 0, InitiatingTeamId: this.state.teamId, OpponentTeamId: this.state.activeCreateMatchTab === '7v7' ? this.state.selectedTeamIdSevens.value : this.state.selectedTeamIdFours.value, OpponentTeamName: this.state.activeCreateMatchTab === '7v7' ? this.state.selectedTeamIdSevens.label : this.state.selectedTeamIdFours.label, DateOne: new Date('1999-01-01'), DateTwo: new Date('1999-01-01'), DateThree: new Date('1999-01-01'), IsAwaitingDates: 1, FormatId: this.state.activeCreateMatchTab === '7v7' ? 1 : 2, FormatName: "", InitiatingTeamName: "", DateCreated: new Date() }
        var response = await postApiData("CREATE_NEW_PENDING_MATCH", [newMatchObj])
        if (response === "failed") {
            this.setState({ errMsg: "You cannot issue a challenge to that team. You either have an active challenge with this team or you have played them within the last two days." })
        }
        else {
            var teamIsChallengingSeven = this.state.teamIsChallengingSeven
            var teamIsChallengingFour = this.state.teamIsChallengingFour
            if (this.state.activeCreateMatchTab == "7v7") 
                teamIsChallengingSeven = 1
            else
                teamIsChallengingFour = 1
            
            const pendingMatchSentRequests = await getApiData("GET_PENDING_MATCH_SENT_REQUESTS", [this.state.teamId, this.state.activeCreateMatchTab == "7v7" ? 1 : 2]);
            this.setState({ pendingMatchSentRequests, errMsg: "", teamIsChallengingSeven, teamIsChallengingFour })
        }
        
    }

    handleMatchDateChange = async (id, date, dateId) =>
    {
        var pendingMatchRequests = this.state.pendingMatchRequests
        const index = pendingMatchRequests.findIndex(x => x.id === id);
        if (index !== -1) {
            pendingMatchRequests[index][dateId] = date
            this.setState({ pendingMatchRequests })
        } 
    }

    handleApproveMatchRequest = async (id) => {
        var approveMatchRequestObj = this.state.pendingMatchRequests.filter(x => x.id === id)
        var formatId = approveMatchRequestObj[0].formatId
        approveMatchRequestObj[0].initiatingTeamName = ""
        if (this.state.teamIsChallengingSeven === 0 && formatId === 1) { 
        
            await postApiData("APPROVE_MATCH_REQUEST", [approveMatchRequestObj[0]])
            const pendingMatchRequests = await getApiData("GET_PENDING_MATCH_REQUESTS", [this.state.teamId]); // Populate pending join requests from API
            this.setState({ pendingMatchRequests, teamIsChallengingSeven: 1 })
        }

        if (this.state.teamIsChallengingFour === 0 && formatId === 2) {

            await postApiData("APPROVE_MATCH_REQUEST", [approveMatchRequestObj[0]])
            const pendingMatchRequests = await getApiData("GET_PENDING_MATCH_REQUESTS", [this.state.teamId]); // Populate pending join requests from API
            this.setState({ pendingMatchRequests, teamIsChallengingFour: 1 })
        }
    }

    handleRejectMatchRequest = async (id) => {
        var rejectMatchRequestObj = this.state.pendingMatchRequests.filter(x => x.id === id)
        await postApiData("REJECT_MATCH_REQUEST", [rejectMatchRequestObj])
        const pendingMatchRequests = await getApiData("GET_PENDING_MATCH_REQUESTS", [this.state.teamId]); // Populate pending join requests from API
        this.setState({ pendingMatchRequests })
    }

    handleAcceptMatchRequestSevens = async () => {
        var acceptMatchObj = { SelectedDate: this.state.selectedDate, TeamId: this.state.teamId, formatId: 1 }
        await postApiData("ACCEPT_MATCH_REQUEST", [acceptMatchObj])
        const upcomingMatches = await getApiData("GET_UPCOMING_MATCHES_BY_TEAM_ID", [this.state.teamId]);
        const pendingMatchRequests = await getApiData("GET_PENDING_MATCH_REQUESTS", [this.state.teamId]);
        const pendingMatchSentRequests = await getApiData("GET_PENDING_MATCH_SENT_REQUESTS", [this.state.teamId, 1]);
        this.setState({ upcomingMatches, pendingMatchRequests, pendingMatchSentRequests })
    }

    handleAcceptMatchRequestFours = async () => {
        var acceptMatchObj = { SelectedDate: this.state.selectedDate, TeamId: this.state.teamId, formatId: 2 }
        await postApiData("ACCEPT_MATCH_REQUEST", [acceptMatchObj])
        const upcomingMatches = await getApiData("GET_UPCOMING_MATCHES_BY_TEAM_ID", [this.state.teamId]);
        const pendingMatchRequests = await getApiData("GET_PENDING_MATCH_REQUESTS", [this.state.teamId]);
        const pendingMatchSentRequests = await getApiData("GET_PENDING_MATCH_SENT_REQUESTS", [this.state.teamId, 2]);
        this.setState({ upcomingMatches, pendingMatchRequests, pendingMatchSentRequests })
    }

    handleRosterRemoval = async (userId) => {
        await postApiData("REMOVE_USER_FROM_ROSTER", [userId])
        const roster = await getApiData("GET_TEAM_ROSTER", [this.state.teamId]);
        this.setState({roster})
    }

    handleWinnerSelectSevens = async (winnerIdSevens) => {
        this.setState({ winnerIdSevens })
    }

    handleWinnerSelectFours = async (winnerIdFours) => {
        this.setState({ winnerIdFours })
    }
    
    handleWinnerSubmitSevens = async (matchId) => {
        var winnerObj = { winnerId: this.state.winnerIdSevens, matchId: matchId, teamId: this.state.teamId }
        const response = await postApiData("DECLARE_WINNER", [winnerObj]);
        var winErrSevens = ""
        if (response === "Mismatch") {
            winErrSevens = "Your selection did not match your opponents. You must both select a winner again."
            const upcomingMatches = await getApiData("GET_UPCOMING_MATCHES_BY_TEAM_ID", [this.state.teamId]);
            this.setState({ upcomingMatches, winErrSevens })
        }
          
        else
        {
            const upcomingMatches = await getApiData("GET_UPCOMING_MATCHES_BY_TEAM_ID", [this.state.teamId]);
            const teamData = await getApiData("GET_TEAM_BY_ID", [this.state.teamId])
            const teamIsChallengingSeven = teamData.isChallengingSeven
            const teamIsChallengingFour = teamData.isChallengingFour
            this.setState({ upcomingMatches, winErrSevens, teamIsChallengingSeven, teamIsChallengingFour })
        }
    }

    handleWinnerSubmitFours = async (matchId) => {
        var winnerObj = { winnerId: this.state.winnerIdFours, matchId: matchId, teamId: this.state.teamId }
        const response = await postApiData("DECLARE_WINNER", [winnerObj]);
        var winErrFours = ""
        if (response === "Mismatch") {
            winErrFours = "Your selection did not match your opponents. You must both select a winner again."
            const upcomingMatches = await getApiData("GET_UPCOMING_MATCHES_BY_TEAM_ID", [this.state.teamId]);
            this.setState({ upcomingMatches, winErrFours })
        }

        else {
            const upcomingMatches = await getApiData("GET_UPCOMING_MATCHES_BY_TEAM_ID", [this.state.teamId]);
            const teamData = await getApiData("GET_TEAM_BY_ID", [this.state.teamId])
           
            const teamIsChallengingSeven = teamData.isChallengingSeven
            const teamIsChallengingFour = teamData.isChallengingFour
            this.setState({ upcomingMatches, winErrFours, teamIsChallengingSeven, teamIsChallengingFour })
        }
    }

    toggleTab = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    toggleMatchTab = async (tab) => {
        var teams = []
        var pendingMatchSentRequests = []
        if (this.state.activeCreateMatchTab !== tab) {
            if (tab === '7v7') {
                 teams = await getApiData("GET_OPPONENTS_LIST", [this.state.teamId, 1])
                 pendingMatchSentRequests = await getApiData("GET_PENDING_MATCH_SENT_REQUESTS", [this.state.teamId, 1]);
            }

            else {
                 teams = await getApiData("GET_OPPONENTS_LIST", [this.state.teamId, 2])
                 pendingMatchSentRequests = await getApiData("GET_PENDING_MATCH_SENT_REQUESTS", [this.state.teamId, 2]);
            }
                
            this.setState({
                activeCreateMatchTab: tab,
                teams: teams,
                pendingMatchSentRequests: pendingMatchSentRequests
            });
        }
    }



    handleDateSelection = (date) => {
        this.setState({ selectedDate:date })
    }

    render() {
        const { teamName, roster, upcomingMatches, pendingJoinRequests, selectedTeamIdSevens, selectedTeamIdFours, matchDate, pendingMatchRequests, sentMatchRequests, selectedFormat, errMsg, pendingMatchSentRequests } = this.state;

            return (
            <Layout>
                <Container>
                    <br />

                    {/* Current Roster */}
                        <Row>
                        {/* Upcoming Matches */}

                            <Col style={{ 'height': 400 }} sm={8} className="bootstrap-grid">
                            <h4>Upcoming Matches</h4>

                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Opponent</th>
                                        <th>Match Date</th>
                                        <th>Maps</th>
                                        <th>Format</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {upcomingMatches.length > 0 ? (

                                        upcomingMatches.map((match) => (
                                            <tr key={match.id}>
                                            <td>{match.teamA_Id === this.state.teamId ? match.opponentName : match.initiatingTeamName}</td>
                                            <td> {moment(match.matchDate).format('MM/DD/YYYY hh:mm A')} EST</td>
                                            <td>{match.maps}</td>
                                            <td>{match.formatName}</td>
                                            </tr>
                                        ))
                                    ) : <tr>
                                        <td colSpan="5" style={{ textAlign: 'center' }}>No upcoming matches found</td>
                                    </tr>}
                                </tbody>
                            </table>
                            <br />
                                {this.state.isCaptain && upcomingMatches.length > 0 ? (
                                    <div>
                                        <b>Pick a winner:</b><br />
                                        <Row>
                                            {upcomingMatches.map(match => (
                                                <>
                                                    {match.formatId === 1 ?
                                                        <Col>
                                                            <h5>7v7 Winner Select</h5>
                                                            {(this.state.teamId === match.teamA_Id && match.teamA_Winner_Selection === 0) || (this.state.teamId === match.teamB_Id && match.teamB_Winner_Selection === 0) ?
                                                                (
                                                                    <>
                                                                        <div style={{ textAlign: 'center' }} key={match.id}>
                                                                            <Button
                                                                                className={this.state.winnerIdSevens === match.teamA_Id ? 'clicked' : ''}
                                                                                color="danger"
                                                                                onClick={() => this.handleWinnerSelectSevens(match.teamA_Id)}
                                                                            >
                                                                                {match.initiatingTeamName}
                                                                            </Button>
                                                                            {' '}
                                                                            <Button
                                                                                className={this.state.winnerIdSevens === match.teamB_Id ? 'clicked' : ''}
                                                                                color="danger"
                                                                                onClick={() => this.handleWinnerSelectSevens(match.teamB_Id)}
                                                                            >
                                                                                {match.opponentName}
                                                                            </Button>
                                                                        </div>
                                                                        <br />
                                                                        <div style={{ color: 'red', fontSize: 12 }}>{this.state.winErrSevens}</div>
                                                                        <div style={{ textAlign: 'center' }}><Button color="primary" onClick={() => this.handleWinnerSubmitSevens(match.id)}> Submit</Button></div>
                                                                    </>
                                                                )
                                                                :
                                                                <div><b>You have already selected a winner</b></div>

                                                            }
                                                        </Col>
                                                        
                                                        :
                                                        <Col>
                                                            <h5>4v4 Winner Select</h5>
                                                            {(this.state.teamId === match.teamA_Id && match.teamA_Winner_Selection === 0) || (this.state.teamId === match.teamB_Id && match.teamB_Winner_Selection === 0) ?
                                                                (
                                                                    <>
                                                                        <div style={{ textAlign: 'center' }} key={match.id}>
                                                                            <Button
                                                                                className={this.state.winnerIdFours === match.teamA_Id ? 'clicked' : ''}
                                                                                color="danger"
                                                                                onClick={() => this.handleWinnerSelectFours(match.teamA_Id)}
                                                                            >
                                                                                {match.initiatingTeamName}
                                                                            </Button>
                                                                            {' '}
                                                                            <Button
                                                                                className={this.state.winnerIdFours === match.teamB_Id ? 'clicked' : ''}
                                                                                color="danger"
                                                                                onClick={() => this.handleWinnerSelectFours(match.teamB_Id)}
                                                                            >
                                                                                {match.opponentName}
                                                                            </Button>
                                                                        </div>
                                                                        <br />
                                                                        <div style={{ color: 'red', fontSize: 12 }}>{this.state.winErrFours}</div>
                                                                        <div style={{ textAlign: 'center' }}><Button color="primary" onClick={() => this.handleWinnerSubmitFours(match.id)}> Submit</Button></div>
                                                                    </>
                                                                    )
                                                                    :
                                                                    <div><b>You have already selected a winner</b></div>
                                                               }
                                                        </Col>
                                                    }
                                                </>
                                             ))}
                                        </Row>
                                    </div>
                                ) : ""}

                        </Col>
                        <Col style={{ 'height': 400 }} sm={4} className="bootstrap-grid">
                            <Nav tabs>
                                <NavItem>
                                    <NavLink
                                        className={this.state.activeTab === 'rosterTab' ? 'active' : ''}
                                        onClick={() => { this.toggleTab('rosterTab'); }}
                                    >
                                        Roster
                                    </NavLink>
                                    </NavItem>
                                    {this.state.isCaptain && ( <NavItem>
                                        <NavLink
                                            className={this.state.activeTab === 'pendingRequestsTab' ? 'active' : ''}
                                            onClick={() => { this.toggleTab('pendingRequestsTab'); }}
                                        >
                                            Pending Requests
                                        </NavLink>
                                    </NavItem>)}
                            </Nav>
                            <TabContent activeTab={this.state.activeTab}>
                                <br />
                                    <TabPane tabId="rosterTab">
                                        <ul style={{ listStyle: 'none', padding: 0, lineHeight:2.5 }}>
                                        {roster.map((user) => (
                                            <li key={user.id}>
                                                <div className="d-flex align-items-center justify-content-between">
                                                    {user.isCaptain === 1 ? <span><b>{user.username}</b></span> :  user.username }
                                                    {this.state.isCaptain && (
                                                        user.isCaptain === 0 ?  
                                                        <div>
                                                            <Button color="danger" onClick={() => this.handleRosterRemoval(user.id)}>Remove</Button>
                                                        </div>
                                                        :
                                                        ""
                                                    )
                                                    }

                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </TabPane>
                                <TabPane tabId="pendingRequestsTab">
                                       
                                    <ul style={{ listStyle: 'none', padding: 0 }}>
                                        {pendingJoinRequests.map((request) => (
                                            <li key={request.id}>
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <span>{request.username}</span>
                                                    <div>
                                                        <Button color="success" onClick={() => this.handleApproveJoinRequest(request.id)}>Approve</Button>
                                                        <Button color="danger" onClick={() => this.handleRejectJoinRequest(request.id)}>Reject</Button>
                                                    </div>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </TabPane>
                            </TabContent>
                        </Col>


                 
                    </Row>
                    <br />
                    <br />
                    {/* Manage Join Requests (Only for Captains) */}
                    {this.state.isCaptain && (
                        <Row>

                            <Col style={{ 'height': 400 }} sm={8} className="bootstrap-grid">
                                <h4>Pending Match Requests</h4>
                                <b>Note: You can only be approve one match per format.</b>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Opponent</th>
                                            <th>Rank</th>
                                            <th>Format</th>
                                            <th>Match Date</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            pendingMatchRequests.length > 0 ?
                                        
                                            pendingMatchRequests.map((request) => (
                                            <tr key={request.id}>
                                                <td>{request.opponentTeamName}</td>
                                                <td>{request.rank}</td>
                                                <td>{request.formatName}</td>
                                                <td>
                                                    <Input
                                                        type="datetime-local" id="dateOne"
                                                        onChange={(e) => this.handleMatchDateChange(request.id, e.target.value, e.target.id)}
                                                    />
                                                    <Input
                                                        type="datetime-local" id="dateTwo"
                                                        onChange={(e) => this.handleMatchDateChange(request.id, e.target.value, e.target.id)}
                                                    />
                                                    <Input
                                                        type="datetime-local" id="dateThree"
                                                        onChange={(e) => this.handleMatchDateChange(request.id, e.target.value, e.target.id)}
                                                    />

                                                </td>
                                                    <td>
                                                        <Button disabled={this.state.teamIsChallenging} color="success" onClick={() => this.handleApproveMatchRequest(request.id)}>Approve</Button>{' '}
                                                    </td>
                                            </tr>
                                            )) : <tr>
                                                    <td colSpan="5" style={{ textAlign: 'center' }}>No match requests</td>
                                                </tr>}
                                    </tbody>
                                </table>
                            </Col>
                            <Col style={{ 'height': 400 }} sm={4} className="bootstrap-grid">
                                    <h4>Send a match request</h4>
                                    <Nav pills>
                                        <NavItem>
                                            <NavLink
                                                className={this.state.activeCreateMatchTab === '7v7' ? 'active' : ''}
                                                onClick={() => this.toggleMatchTab('7v7')}
                                            >
                                                7 v 7 Ladder
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className={this.state.activeCreateMatchTab === '4v4' ? 'active' : ''}
                                                onClick={() => this.toggleMatchTab('4v4')}
                                            >
                                                4 v 4 Ladder
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                    <br />
                                    <TabContent activeTab={this.state.activeCreateMatchTab}>
                                        <TabPane tabId="7v7">
                                            {this.state.teamIsChallengingSeven === 1 ?
                                                <>
                                                    {pendingMatchSentRequests.length > 0 ?
                                                        <ul style={{ listStyle: 'none', padding: 0 }}>
                                                            {pendingMatchSentRequests.map((request) => (
                                                                <li key={request.id}>
                                                                    <div>
                                                                        <span>Opponent: <b>{request.opponentTeamId === this.state.teamId ? request.initiatingTeamName : request.opponentTeamName}</b></span> <br />
                                                                        <span>Format: <b>{request.formatName}</b></span> <br />
                                                                        <span>
                                                                            {

                                                                                request.opponentTeamId === this.state.teamId ?
                                                                                    <><div>Dates:</div> <b>Waiting for opponent to select dates</b></>
                                                                                    :
                                                                                    request.isAwaitingDates === 1 ? (
                                                                                        <><div>Dates:</div> <b>Waiting for opponent to select dates</b></>
                                                                                    ) : (
                                                                                        <>
                                                                                            <span>Select a match date</span> <br />
                                                                                            <Button
                                                                                                id="dateOne"
                                                                                                onClick={() => this.handleDateSelection(request.dateOne)}
                                                                                                className={this.state.selectedDate === request.dateOne ? 'clicked' : ''}
                                                                                            >
                                                                                                {moment(request.dateOne).format('MM/DD/YYYY hh:mm A')} EST
                                                                                            </Button>

                                                                                            <Button
                                                                                                id="dateTwo"
                                                                                                onClick={() => this.handleDateSelection(request.dateTwo)}
                                                                                                className={this.state.selectedDate === request.dateTwo ? 'clicked' : ''}
                                                                                            >
                                                                                                {moment(request.dateTwo).format('MM/DD/YYYY hh:mm A')} EST
                                                                                            </Button>
                                                                                            <Button
                                                                                                id="dateThree"
                                                                                                onClick={() => this.handleDateSelection(request.dateThree)}
                                                                                                className={this.state.selectedDate === request.dateThree ? 'clicked' : ''}
                                                                                            >
                                                                                                {moment(request.dateThree).format('MM/DD/YYYY hh:mm A')} EST
                                                                                            </Button>

                                                                                            <div style={{ textAlign: 'center' }}> <br /><Button onClick={this.handleAcceptMatchRequestSevens} color="danger">Accept Match</Button></div>
                                                                                        </>
                                                                                    )}
                                                                        </span>
                                                                    </div>
                                                                </li>
                                                            ))}
                                                        </ul> : <div><b>You cannot send match requests at this time.</b></div>}
                                                </>
                                                :
                                                <form onSubmit={this.handleCreateMatchSubmit}>
                                                    <b>Note: You can only challenge teams that are above you, up to 5 ranks</b>
                                                    <Select
                                                        options={this.state.teams.map(team => ({ value: team.id, label: team.teamName }))}
                                                        value={selectedTeamIdSevens}
                                                        onChange={(selectedOption) => this.setState({ selectedTeamIdSevens: selectedOption })}
                                                        placeholder="Choose an opponent"
                                                    /> 
                                                    <br />
                                                    {errMsg && <div><span style={{ color: 'red' }}>{errMsg}</span></div>}
                                                    <Button type="submit" color="primary">Create Match</Button>
                                                </form>
                                            }

                                        </TabPane>
                                        <TabPane tabId="4v4">
                                            
                                            {this.state.teamIsChallengingFour === 1 ?
                                                <>
                                                    {pendingMatchSentRequests.length > 0 ?
                                                        <ul style={{ listStyle: 'none', padding: 0 }}>
                                                            {pendingMatchSentRequests.map((request) => (
                                                                <li key={request.id}>
                                                                    <div>
                                                                        <span>Opponent: <b>{request.opponentTeamId === this.state.teamId ? request.initiatingTeamName : request.opponentTeamName}</b></span> <br />
                                                                        <span>Format: <b>{request.formatName}</b></span> <br />
                                                                        <span>
                                                                            {

                                                                                request.opponentTeamId === this.state.teamId ?
                                                                                    <><div>Dates:</div> <b>Waiting for opponent to select dates</b></>
                                                                                    :
                                                                                    request.isAwaitingDates === 1 ? (
                                                                                        <><div>Dates:</div> <b>Waiting for opponent to select dates</b></>
                                                                                    ) : (
                                                                                        <>
                                                                                            <span>Select a match date</span> <br />
                                                                                            <Button
                                                                                                id="dateOne"
                                                                                                onClick={() => this.handleDateSelection(request.dateOne)}
                                                                                                className={this.state.selectedDate === request.dateOne ? 'clicked' : ''}
                                                                                            >
                                                                                                {moment(request.dateOne).format('MM/DD/YYYY hh:mm A')} EST
                                                                                            </Button>

                                                                                            <Button
                                                                                                id="dateTwo"
                                                                                                onClick={() => this.handleDateSelection(request.dateTwo)}
                                                                                                className={this.state.selectedDate === request.dateTwo ? 'clicked' : ''}
                                                                                            >
                                                                                                {moment(request.dateTwo).format('MM/DD/YYYY hh:mm A')} EST
                                                                                            </Button>
                                                                                            <Button
                                                                                                id="dateThree"
                                                                                                onClick={() => this.handleDateSelection(request.dateThree)}
                                                                                                className={this.state.selectedDate === request.dateThree ? 'clicked' : ''}
                                                                                            >
                                                                                                {moment(request.dateThree).format('MM/DD/YYYY hh:mm A')} EST
                                                                                            </Button>

                                                                                            <div style={{ textAlign: 'center' }}> <br /><Button onClick={this.handleAcceptMatchRequestFours} color="danger">Accept Match</Button></div>
                                                                                        </>
                                                                                    )}
                                                                        </span>
                                                                    </div>
                                                                </li>
                                                            ))}
                                                        </ul> : <div><b>You cannot send match requests at this time.</b></div>}
                                                </>
                                                :
                                                <form onSubmit={this.handleCreateMatchSubmit}>
                                                    <b>Note: You can only challenge teams that are above you, up to 5 ranks</b>
                                                    <Select
                                                        options={this.state.teams.map(team => ({ value: team.id, label: team.teamName }))}
                                                        value={selectedTeamIdFours}
                                                        onChange={(selectedOption) => this.setState({ selectedTeamIdFours: selectedOption })}
                                                        placeholder="Choose an opponent"
                                                    />
                                                    <br />
                                                    {errMsg && <div><span style={{ color: 'red' }}>{errMsg}</span></div>}
                                                    <Button type="submit" color="primary">Create Match</Button>
                                                </form>
                                            }

                                        </TabPane>
                                    </TabContent>
                                </Col>
                        </Row>
                    )}
                </Container>
            </Layout>
        );
    }
}

export default ManageTeam;
