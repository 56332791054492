import React, { Component } from 'react';
import { postApiData } from '../fetchApi';
import {
    Container,
    Row,
    Col,
    Form,
    FormGroup,
    Label,
    Input,
    Button,
} from 'reactstrap';
import { Layout } from './Layout'

class Forgot extends Component {
    constructor(props) {
        super(props);
        this.state = {
            password: "",
            confirmPassword: "",
            errMsg: ""
        };
    }

    handleInputChange = (event) => {
        const { name, value, type, checked } = event.target;
        const newValue = type === 'checkbox' ? checked : value;
        this.setState({ [name]: newValue });
    }

    handleReset = async () => {
        const urlParts = window.location.href.split('/');
        const guid = urlParts[urlParts.length - 2];
        
        if (this.state.password === this.state.confirmPassword) {
            if (guid) {
                var passwordResetObj = { password: this.state.password, guid: guid }
                await postApiData("RESET_PASSWORD", [passwordResetObj])
                if (window.location.href.includes("localhost"))
                    window.location.href = "/";
                else
                    window.location.href = "https://www.tribes3ladder.com/";
            }
        }
        else
            this.setState({errMsg: "Passwords do not match"})
    }

    render() {
        return (
            <Layout showNavMenu={false}>
                <Container className="centered-container">
                    <Row style={{ width: "100%" }} className="justify-content-center">
                        <Col sm="6">
                                <Form onKeyPress={this.handleKeyPress}>
                                    <FormGroup>
                                        <Label for="username">New Password</Label>
                                        <Input
                                            type="password"
                                            name="password"
                                            id="password"
                                            value={this.state.password}
                                            onChange={this.handleInputChange}
                                        />
                                    </FormGroup>

                                    <FormGroup>
                                        <Label for="password">Confirm New Password</Label>
                                        <Input
                                            type="password"
                                            name="confirmPassword"
                                            id="confirmPassword"
                                            value={this.state.confirmPassword}
                                            onChange={this.handleInputChange}
                                        />
                                    </FormGroup>

                                   <br />
                                    <div><span style={{ color: "red" }} >{this.state.errMsg}</span></div> <br />

                                    <Button color="primary" onClick={this.handleReset}>
                                        Reset
                                    </Button><br /><br />
                                </Form>
                        </Col>
                    </Row>
                </Container>
            </Layout>
        );
    }
}

export default Forgot;
