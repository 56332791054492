import React, { Component } from 'react';
import { postApiData } from '../fetchApi';

class Verify extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

      componentDidMount = async ()=> {
        const urlParts = window.location.href.split('/');
        const guid = urlParts[urlParts.length - 2];

        if (guid) {
            await postApiData("VERIFY_ACCOUNT", [guid]);
            window.location.href = "https://tribes3ladder.com/";
          }
      }

    render() {
        return <div></div>;
    }
}

export default Verify;
