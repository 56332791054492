import Verify from "./components/Verify"
import Privacy from "./components/PrivacyPolicy"
import Forgot from "./components/Forgot"
import Home from "./components/Home"
import ManageTeam from "./components/ManageTeam"
import Login from "./components/Login"
import Landing from "./components/Landing"

const AppRoutes = [
    {
        index: true,
        element: <Landing />
    },
    {
        path: '/login',
        element: <Login />
    },
    {
        path: '/forgot/:guid',
        element: <Forgot />
    },
    {
        path: '/verify/:guid',
        element: <Verify />
    },
    {
        path: '/privacy-policy',
        element: <Privacy />
    },

    {
        path: '/home',
        element: <Home />
    },

    {
        path: '/manage-team',
        element: <ManageTeam />
    },


];

export default AppRoutes;
