import React, { Component, lazy, Suspense } from 'react';
import { Container, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import { getApiData } from './../fetchApi';
import { Layout } from './Layout';
import Cookies from 'js-cookie';

// Import components lazily
const Profile = lazy(() => import('./Profile'));
const MainLadder = lazy(() => import('./MainLadder'));
const ManageTeam = lazy(() => import('./ManageTeam'));
const Admin = lazy(() => import('./Admin'));
const LadderAdmin = lazy(() => import('./LadderAdmin'));
const MatchHistory = lazy(() => import('./MatchHistory'));
const MatchAdmin = lazy(() => import('./MatchAdmin'));
const Rules = lazy(() => import('./Rules'));

class Home extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isCaptain: false,
            isAdmin: false,
            activeTab: 'Profile',
            teamId: 0
        };
    }

    componentDidMount = async () => {
      
        var userData = await getApiData('GET_USER_INFO', [sessionStorage.getItem('x-params')]);
        if (userData) {
            this.setState({
                isCaptain: userData.isCaptain === 1,
                isAdmin: userData.isAdmin === 1,
                teamId: userData.teamId,
            });
        }
    }

    toggleTab = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({ activeTab: tab });
        }
    }

    logout = () => {
        sessionStorage.clear()
        window.location.href = "/"
        Cookies.remove('rememberMe')
    }

    render() {
        const { isCaptain, isAdmin, activeTab } = this.state;

        return (
            <>
                <Layout showNavMenu={true}>
                    <Container>
                        <Nav tabs>
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: activeTab === 'Profile' })}
                                    onClick={() => { this.toggleTab('Profile'); }}
                                >
                                    Profile
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: activeTab === 'MainLadder' })}
                                    onClick={() => { this.toggleTab('MainLadder'); }}
                                >
                                    Ladder
                                </NavLink>
                            </NavItem>
                            
                            {this.state.teamId > 0 && (
                                <>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === 'ManageTeam' })}
                                            onClick={() => { this.toggleTab('ManageTeam'); }}
                                        >
                                            Team
                                        </NavLink>
                                    </NavItem>
                                <NavItem>
                                <NavLink
                                        className={classnames({ active: activeTab === 'MatchHistory' })}
                                        onClick={() => { this.toggleTab('MatchHistory'); }}
                                    >
                                        Team Match History
                                    </NavLink>
                                </NavItem>
                                </>)}

                            <NavItem>
                                <NavLink
                                    className={classnames({ active: activeTab === 'GlobalMatchHistory' })}
                                    onClick={() => { this.toggleTab('GlobalMatchHistory'); }}
                                >
                                    Global Match History
                                </NavLink>
                            </NavItem>
                            {isAdmin &&<><NavItem>
                                <NavLink
                                    className={classnames({ active: activeTab === 'Admin' })}
                                    onClick={() => { this.toggleTab('Admin'); }}
                                >
                                    Admin
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: activeTab === 'LadderAdmin' })}
                                    onClick={() => { this.toggleTab('LadderAdmin'); }}
                                >
                                    Ladder Admin
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: activeTab === 'MatchAdmin' })}
                                    onClick={() => { this.toggleTab('MatchAdmin'); }}
                                >
                                    Match Admin
                                </NavLink>
                                </NavItem>
                            </>}
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: activeTab === 'Rules' })}
                                    onClick={() => { this.toggleTab('Rules'); }}
                                >
                                    Rules
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: activeTab === 'LogoutTab' })}
                                    onClick={() => { this.logout() }}
                                >
                                    Logout
                                </NavLink>
                            </NavItem>
                           
                        </Nav>
                        <TabContent activeTab={activeTab}>
                            <Suspense fallback={<div>Loading...</div>}>
                                {activeTab === 'Profile' && <TabPane tabId="Profile"><Profile /></TabPane>}
                                {activeTab === 'MainLadder' && <TabPane tabId="MainLadder"><MainLadder /></TabPane>}
                                {activeTab === 'MatchHistory' && <TabPane tabId="MatchHistory"><MatchHistory teamId={this.state.teamId} /></TabPane>}
                                {activeTab === 'GlobalMatchHistory' && <TabPane tabId="GlobalMatchHistory"><MatchHistory teamId={0} /></TabPane>}
                                {activeTab === 'ManageTeam' && <TabPane tabId="ManageTeam"><ManageTeam teamId={this.state.teamId} isCaptain={this.state.isCaptain} /></TabPane>}
                                {activeTab === 'Admin' && <TabPane tabId="Admin"><Admin /></TabPane>}
                                {activeTab === 'LadderAdmin' && <TabPane tabId="LadderAdmin"><LadderAdmin /></TabPane>}
                                {activeTab === 'MatchAdmin' && <TabPane tabId="MatchAdmin"><MatchAdmin /></TabPane>}
                                {activeTab === 'Rules' && <TabPane tabId="Rules"><Rules /></TabPane>}
                            </Suspense>
                        </TabContent>
                    </Container>
                </Layout>
            </>
        );
    }
}

export default Home;
