import React, { Component } from 'react';
import {
    Container,
    Row,
    Col,
    Form,
    FormGroup,
    Label,
    Input,
    Button,
} from 'reactstrap';
import getApiData, { postApiData } from './../fetchApi';
import { Layout } from './Layout';
import Cookies from 'js-cookie'; // Import the js-cookie library
import ReCAPTCHA from "react-google-recaptcha";
import Logo from './../Images/T3l-logo.png';

class Login extends Component {
    constructor() {
        super();
        this.state = {
            username: '',
            password: '',
            rememberMe: false,
            errMsg: "",
            mode: "login",
            accountCreationUsername: '',
            email: '',
            accountCreationPassword: '',
            confirmPassword: '',
            message: '',
            error: '',
            recaptchaValue: ""
        };
    }

    componentDidMount = async () => {
        const rememberMeCookie = Cookies.get('rememberMe');
        if(rememberMeCookie !== undefined)
            sessionStorage.setItem("x-params", rememberMeCookie)
        if (sessionStorage.getItem("x-params") !== undefined && sessionStorage.getItem("x-params") !== null)
        {
            if (window.location.href.includes("localhost"))    
                window.location.href = "/home";
            else
                window.location.href = "https://www.tribes3ladder.com/home";
        }
       
            
    }

    componentDidUpdate = async () => {
        if (sessionStorage.getItem("x-params") !== undefined && sessionStorage.getItem("x-params") !== null)
        {
            if (window.location.href.includes("localhost"))    
                window.location.href = "/home";
            else
                window.location.href = "https://www.tribes3ladder.com/home";
        }
    }

    handleInputChange = (event) => {
        const { name, value, type, checked } = event.target;
        const newValue = type === 'checkbox' ? checked : value;
        this.setState({ [name]: newValue });
    }

    handleLogin = async () => {
        const { username, password, rememberMe } = this.state;
        var loginObj = {username: username, password: password}
        var guid = await postApiData("LOGIN", [loginObj])
        if (guid === "Error")
            this.setState({ errMsg: "Invalid Login" })
        else {
            // Save the rememberMe value in a cookie if checked
            if (rememberMe) {
                Cookies.set('rememberMe', guid.guid, { expires: 365 }); // Set a cookie that doesn't expire for a year
            } else {
                Cookies.remove('rememberMe'); // Remove the rememberMe cookie
            }
            sessionStorage.setItem("x-params", guid.guid);
            this.forceUpdate();
        }
            
    }

    handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            this.handleLogin();
        }
    };

    handleBack = () => {
        this.setState({mode:'login'})
    }

    setAccountCreation = () => {
        this.setState({mode:"create"})
    }

    setForgotPassword = () => {
        this.setState({ mode: "forgot" })
    }

    handleReset = async () => {
        var result = await postApiData("SEND_RESET_LINK", [this.state.email])
        if (result === "Not Found") {
            this.setState({ errMsg: "Email not found" })
        }
        else {
            this.setState({
                message: `A password reset email has been sent to ${this.state.email}. Please check spam/junk/all mail if you don't see it in your inbox`,
                error: '',
            })
        }
    }

    handleSave = async () => {
       // if (this.state.recaptchaValue != "") {
            const { email, accountCreationUsername, accountCreationPassword, confirmPassword } = this.state;

            // Check if passwords match
            if (accountCreationPassword !== confirmPassword) {
                this.setState({ error: 'Passwords do not match', message: '' });
                return;
            }

            // Send an API request to check if the email exists
            const emailExists = await this.checkEmailExists(email);

            if (emailExists) {
                this.setState({
                    error: 'Email already exists in the database',
                    message: '',
                });
            } else {
                var accountObj = { username: accountCreationUsername, password: accountCreationPassword, email: email }
                var result = await postApiData("SAVE_ACCOUNT", [accountObj])

                if (result === 'Success') {
                    this.setState({
                        message: `A verification email has been sent to ${email}. Please check spam/junk/all mail if you don't see it in your inbox`,
                        error: '',
                    })
                }

                else {
                    this.setState({
                        error: 'All fields required',
                    })
                }


           // }
        }
    };

    checkEmailExists = async (email) => {
        var exists = await getApiData("CHECK_EXISTING_EMAIL", [email])
        if (exists === "null")
            return false
        else
            return true
    };

    handleRecaptchaChange = (value) => {
        this.setState({recaptchaValue: value})
    }


    render() {
        return (
            <Layout showNavMenu={false}>
                <br />
                <div style={{ textAlign: 'center' }} className="justify-content-center"> <img className="logo logo-login" src={Logo}></img></div>
                <Container className="centered-container">
                  
                    <Row style={{width:"100%"}} className="justify-content-center">
                        <Col sm="6">
                            
                            {this.state.mode === 'login' && (
                                <Form onKeyPress={this.handleKeyPress}>
                                    <FormGroup>
                                        <Label for="username">Email</Label>
                                        <Input
                                            type="text"
                                            name="username"
                                            id="username"
                                            value={this.state.username}
                                            onChange={this.handleInputChange}
                                        />
                                    </FormGroup>

                                    <FormGroup>
                                        <Label for="password">Password</Label>
                                        <Input
                                            type="password"
                                            name="password"
                                            id="password"
                                            value={this.state.password}
                                            onChange={this.handleInputChange}
                                        />
                                    </FormGroup>

                                    <FormGroup check>
                                        <Label check>
                                            <Input
                                                type="checkbox"
                                                name="rememberMe"
                                                checked={this.state.rememberMe}
                                                onChange={this.handleInputChange}
                                            />
                                            Remember Me
                                        </Label>
                                    </FormGroup> <br />
                                    <div><span style={{ color: "red" }} >{this.state.errMsg}</span></div> <br />

                                    <Button color="primary" onClick={this.handleLogin}>
                                        Login
                                    </Button> <br /><br />

                                    <Button color="primary" onClick={this.setAccountCreation}>
                                        Create New Account
                                    </Button><br /><br />

                                    <button className="btn btn-warning" onClick={this.setForgotPassword}>
                                        Forgot Password
                                    </button><br /><br />

                                    <a href="/" className="btn btn-danger">Back</a>
                                </Form>
                            )}
                            {this.state.mode === 'forgot' && (
                                <Form>
                                    <FormGroup>
                                        <Label for="email">Email</Label>
                                        <Input
                                            type="email"
                                            name="email"
                                            value={this.state.email}
                                            onChange={this.handleInputChange}
                                            className="form-control"
                                        />
                                    </FormGroup>

                                   
                                    <br />
                                    <div><span style={{ color: "red" }} >{this.state.errMsg}</span></div> <br />
                                    {this.state.message && <div style={{ color: "green" }} className="message">{this.state.message}</div>}
                                    <Button color="primary" onClick={this.handleReset}>
                                        Send Reset Link
                                    </Button> <br /><br />
                                </Form>
                            )}
                            {this.state.mode === 'create' && (
                                <div>
                                    <h2>Account Creation Form</h2>
                                    <FormGroup>
                                        <label>
                                            <b>Display Name:</b>
                                            <input
                                                type="text"
                                                name="accountCreationUsername"
                                                value={this.state.accountCreationUsername}
                                                onChange={this.handleInputChange}
                                                className="form-control"
                                            />
                                        </label>
                                    </FormGroup>
                                    <FormGroup>
                                        <label>
                                            <b>Email:</b>
                                            <input
                                                type="email"
                                                name="email"
                                                value={this.state.email}
                                                onChange={this.handleInputChange}
                                                className="form-control"
                                            />
                                        </label>
                                    </FormGroup>
                                    <FormGroup>
                                        <label>
                                            <b>Password:</b>
                                            <input
                                                type="password"
                                                name="accountCreationPassword"
                                                value={this.state.accountCreationPassword}
                                                onChange={this.handleInputChange}
                                                className="form-control"
                                            />
                                        </label>
                                    </FormGroup>
                                    <FormGroup>
                                        <label>
                                            <b>Confirm Password:</b>
                                            <input
                                                type="password"
                                                name="confirmPassword"
                                                value={this.state.confirmPassword}
                                                onChange={this.handleInputChange}
                                                className="form-control"
                                            />
                                        </label>
                                    </FormGroup>
                                    <br />
                                    <a href="/privacy-policy" target="_blank"><b>View Privacy Policy</b></a>
                                    <br />
                                    <br />
                                    {/*<div className="recaptcha-container">*/}
                                    {/*<ReCAPTCHA style={{textAlign:"center", margin:"0 auto"}}*/}
                                    {/*    sitekey="6Lf-_fQoAAAAADno0b6eYwVJPpyWc8b8DulUgSLR"*/}
                                    {/*    onChange={this.handleRecaptchaChange}*/}
                                    {/*    />*/}
                                    {/*</div>*/}
                                    {/*<br />*/}
                                    <button className="btn btn-primary" onClick={this.handleSave}>Submit</button> &nbsp;
                                    <button className="btn btn-warning" onClick={this.handleBack}>Back</button>
                                    {this.state.error && <div style={{color:"red"}} className="error">{this.state.error}</div>}
                                    {this.state.message && <div style={{ color: "green" }} className="message">{this.state.message}</div>}
                                </div>
                            )}
                        </Col>
                    </Row>
                </Container>
            </Layout>
        );
    }
}

export default Login;
