import React, { Component } from 'react';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import Logo from './../Images/T3l-logo.png';

export class NavMenu extends Component {
  static displayName = NavMenu.name;

  constructor (props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true
    };
  }

  toggleNavbar () {
    this.setState({
      collapsed: !this.state.collapsed
    });
    }



  render() {
    return (
      <header>
            <Navbar className="menu-background navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light>
                <div className="row">
                    <div style={{ textAlign: 'center' }} className="col-lg-12">
                        <NavbarBrand tag={Link} to="/home"><img className='logo logo-nav' src={Logo}></img></NavbarBrand>
                    </div>
                  
                </div>
        </Navbar>
      </header>
    );
  }
}
